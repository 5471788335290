import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import parse from 'html-react-parser';
import { Form, FormField, FormRef, validators } from '@faxi/web-form';
import {
  ModalProps,
  useCallbackRef,
  useFormButtons,
  useUtilities,
} from '@faxi/web-component-library';
import { FormActions, Icon, InfoCard, TextareaField } from 'components';
import { EarnedReward } from 'models';
import { apiGamification } from 'modules';

import * as Styled from './DeactivateRewardModal.styles';

type DeactivateRewardModalProps = ModalProps & {
  reward: EarnedReward;
  onClose: () => void;
  onDeactivate: () => void;
};

const DeactivateRewardModal = (props: DeactivateRewardModalProps) => {
  const { reward, onClose, onDeactivate } = props;

  const { organisationId } = useParams() as {
    organisationId: string;
  };

  const { showSnackBar } = useUtilities();

  const [form, formRef] = useCallbackRef<FormRef>();

  const [FormButtons] = useFormButtons({
    submitLabel: t('deactivate'),
    cancelLabel: t('cancel'),
  });

  const validations = useMemo(
    () => ({
      reasonForDeactiovation: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('label-field_deactivation_reason'),
          })
        ),
        validators.general.maxLength(
          200,
          t('validation-field_validation_max_length', {
            fieldname: t('label-field_deactivation_reason').toLowerCase(),
            number: '200',
          })
        ),
      ],
    }),
    []
  );

  const handleDeactivateReward = useCallback(
    async (value: { reasonForDeactiovation: string }) => {
      const { reasonForDeactiovation } = value;

      try {
        const { data } = await apiGamification.deactivateReward(
          reward.depot_instance_reward_id!,
          organisationId,
          reasonForDeactiovation
        );

        if (data) {
          onDeactivate();
          onClose?.();
          showSnackBar({
            actionButtonText: t('dismiss'),
            text: parse(
              t('earned_reward-toast_successfully_deactivated', {
                rewardname: `<b>${reward.reward}</b>`,
              })
            ) as string,
            variant: 'success',
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [
      onClose,
      onDeactivate,
      organisationId,
      reward.depot_instance_reward_id,
      reward.reward,
      showSnackBar,
    ]
  );

  const modalForm = useCallback(
    ({ children }: any) => (
      <Form
        children={children}
        ref={formRef}
        onSubmit={handleDeactivateReward}
      />
    ),
    [formRef, handleDeactivateReward]
  );

  return (
    <Styled.Modal
      onClose={() => {
        onClose?.();
      }}
      renderAsPortal
      childrenWrapper={modalForm}
      hasCloseButton={false}
      title={
        parse(
          t('earned_reward-dialog_deactivated_reward', {
            rewardname: `<b>${reward.reward}</b>`,
          })
        ) as string
      }
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Cancel onClick={onClose} />
          <FormButtons.Submit
            type="submit"
            variant="delete-ghost"
            icon={<Icon name="ban" />}
            iconPosition="right"
            disabled={!form?.syncFormValid}
          />
        </FormActions>
      }
    >
      <InfoCard
        orientation="vertical"
        text={t('earned_rewards-modal_alert_deactivate')}
      />

      <FormField
        name="reasonForDeactiovation"
        placeholder={t('hint-label_type_reason')}
        autoComplete="off"
        component={TextareaField}
        className="kinto-modal-rewards__description"
        noresize
        required
        requiredLabel={t('global-input_field_required_label')}
        validate={validations.reasonForDeactiovation}
      />
    </Styled.Modal>
  );
};

export default DeactivateRewardModal;
