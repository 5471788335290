import httpClient from '../httpClient';
import { Booking, BookingAppointmentType, BookingStatus } from 'models';
import { SortDirections } from '@faxi/web-component-library';

const getBookings = async (args: {
  oid: number;
  type: BookingAppointmentType;
  appointment_status?: BookingStatus[];
  count: number;
  offset: number;
  sort_by?: Partial<keyof Booking>;
  sort_direction?: SortDirections;
}) => {
  const {
    oid,
    type,
    count,
    appointment_status = ['finished', 'upcoming', 'started', 'canceled'],
    ...restArgs
  } = args;

  const appointment_status_params: string = appointment_status.reduce(
    (accumulator, currentValue) =>
      `${accumulator}&appointment_status[]=${currentValue}`,
    ''
  );

  return httpClient
    .get(
      `admin/appointments/organisation/${oid}?${appointment_status_params}`,
      {
        params: { type, count, ...restArgs },
      }
    )
    .then((res) => ({
      count,
      total_count: res.data.data.total,
      appointments: res.data.data.appointments,
    }));
};

export default {
  getBookings,
};
